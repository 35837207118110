.spg-search-editor_container {
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 14px))));
  width: 100%;
}

.svc-full-container {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow) !important;
}

.svc-string-editor__content {
  width: auto;
}

.sa-question__title--draggable:after {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg%3E%3Cpolygon style='fill: %23ff7f40' points='13,5 12,6 13,7 9,7 9,3 10,4 11,3 8,0 5,3 6,4 7,3 7,7 3,7 4,6 3,5 0,8 3,11 4,10 3,9 7,9 7,13 6,12 5,13 8,16 11,13 10,12 9,13 9,9 13,9 12,10 13,11 16,8 '/%3E%3C/g%3E%3C/svg%3E ");
}

.sa-table_action-container .sa-table_drag-button use,
.sa-table_action-container .sa-table_drag-button:hover use,
.sa-table input:focus {
  fill: #ff7f40;
  border-color: #ff7f40;
}

.sa-table__btn {
  padding: 0 1rem;
  width: auto;
}

.sa-panel__header {
  display: none;
}

.sa-table_header-extension + .sa-table_header-extension {
  display: none;
}

:is(.dark) {
  .sa-question__content,
  .sa-tabulator__header {
    background-color: #1e293b !important;
    border: 1px solid rgba(var(--tgx-border-rgb), var(--tw-border-opacity));
  }

  .sa-table__global-filter,
  .sa-table__header-extension {
    &::placeholder {
      color: #fff !important;
    }
  }

  .js-plotly-plot .plotly .main-svg {
    background: unset !important;
  }

  .sa-question_select-wrapper .sa-question_select {
    background: rgba(var(--tgx-bg-default-rgb), var(--tw-bg-opacity)) !important;
    border: 1px solid rgba(var(--tgx-border-rgb), var(--tw-border-opacity)) !important;
  }

  .sa-text-table {
    background-color: rgba(var(--tgx-bg-default-rgb), var(--tw-bg-opacity)) !important;
  }

  .sa-text-table__cell {
    background-color: unset !important;
    border: 1px solid rgba(var(--tgx-border-rgb), var(--tw-border-opacity)) !important;
  }

  .svc-creator__content-wrapper {
    background: #1e293b !important;
    border: 1px solid rgba(var(--tgx-border-rgb), var(--tw-border-opacity)) !important;
  }

  .svc-top-bar,
  .svc-tabbed-menu-item--selected,
  .spg-panel__content,
  .spg-root-modern,
  .spg-search-editor_container,
  .sd-table_row,
  .spg-checkbox_rectangle {
    background: #1e293b !important;
    border: 1px solid rgba(var(--tgx-border-rgb), var(--tw-border-opacity)) !important;
  }

  .svc-side-bar_container-actions,
  .svc-side-barcontainer-title,
  .svc-side-bar_container-content {
    background: #1e293b !important;
    border: 1px solid rgba(var(--tgx-border-rgb), var(--tw-border-opacity)) !important;
  }

  .spg-input,
  .sd-element_title--num-inline,
  .sv-button-groupitem,
  .spg-matrixdynamic_placeholder,
  .spg-panel__title--expanded,
  .svc-side-bar__container-actions,
  .svc-side-bar__container-title,
  .sv-button-group__item,
  .sd-element__title--num-inline,
  .spg-matrixdynamic__placeholder,
  .spg-question__header--location--left,
  .spg-dropdown_select-wrapper,
  .st-panel__title,
  .sd-table__cell--column-title {
    background: #263450 !important;
    color: #fff !important;
  }

  .st-table__cell,
  .st-table__cell--row-text,
  .st-comment,
  .sd-translation-line-skeleton,
  .sd-table__question-wrapper {
    background: #1e293b !important;
    color: white !important;
  }

  .st-comment::placeholder {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090)) !important;
  }

  .sd-table--has-footer,
  .sd-table__cell--column-title,
  .svc-side-bar__container-content {
    background: #263450 !important;
  }

  .sv-button-group__item--selected {
    font-weight: bold;
  }

  .sd-root-modern,
  .sd-table__row {
    background: #1e293b !important;
  }

  .sv-popup.sv-property-editor .sd-root-modern {
    background: unset !important;
  }

  .sl-table__cell .svc-action-button,
  .sl-table__cell .svc-action-button:hover,
  .sl-table__cell .svc-action-button:focus,
  .sl-table__cell .svc-action-button:hover:enabled,
  .sl-table__cell .svc-action-button:focus:enabled,
  .svc-test-tab__content .sd-body--empty,
  .svc-text,
  .spg-search-editor_input,
  .spg-search-editor_input::placeholder,
  .spg-search-editor_search-icon use {
    color: #fff !important;
    fill: #fff !important;
  }

  .svc-btn .svc-text {
    color: var(--sjs-primary-backcolor) !important;
  }

  .svc-tabbed-menu-item .svc-text,
  .sv-action-bar-item__title,
  .spg-clearfix .spg-row .ng-star-inserted {
    color: white !important;
  }

  .sv-popup--dropdown .sv-popup__body-content {
    background: #1e293b !important;
  }

  .sv-list__item-body,
  .svc-tabbed-menu-item,
  .spg-theme-group-caption {
    color: white !important;
  }

  .sv-list__item-body:hover,
  .svc-tabbed-menu-item:hover,
  .sv-action-bar-item:hover {
    background: rgb(38, 52, 80) !important;
  }

  .sd-dropdown {
    [aria-expanded='true'] {
      color: white;
    }

    [aria-expanded='false'] {
      color: white;
    }
  }

  .spg-input-container__input {
    color: white !important;
  }
}
